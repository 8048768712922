const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const retry = async (fn, n = 10, ms = 1000) => {
  try {
    return await fn();
  } catch (err) {
    if (n === 1) throw err;
    await sleep(ms);
    return await retry(fn, n - 1, ms);
  }
};

export const repeat = async (fn, times = 10, delay = 1000) => {
  let shouldStop = false;
  const stop = () => (shouldStop = true);
  let result;
  for (let n = 0; n < times; n++) {
    result = await fn(stop);
    if (shouldStop) {
      break;
    }
    await sleep(delay);
  }
  return result;
};

export default retry;

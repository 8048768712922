export const THEME_FONT_KEY_MAP = {
  Title: 'font_0',
  Menu: 'font_1',
  'Page title': 'font_2',
  'Heading XL': 'font_3',
  'Heading L': 'font_4',
  'Heading M': 'font_5',
  'Heading S': 'font_6',
  'Body L': 'font_7',
  'Body M': 'font_8',
  'Body S': 'font_9',
  'Body XS': 'font_10',
};

export default [
  {
    forType: 'VideoPost',
    view: 'FeaturedInner',
    fieldId: 'video',
    key: 'comp.style',
    value: 'wp2',
  },
  {
    forType: 'TextPost',
    view: 'FeaturedInner',
    fieldId: 'text',
    key: 'comp.style',
    value: 'Body M',
  },
  {
    forType: 'PhotoPost',
    view: 'FeaturedInner',
    fieldId: 'photo',
    key: 'comp.style',
    value: 'wp2',
  },
  {
    forType: 'Post',
    view: 'PostsList',
    fieldId: 'lineSeparator',
    key: 'comp.style',
    value: 'hl1',
  },
  {
    forType: 'Post',
    view: 'PostsList',
    fieldId: 'title',
    key: 'comp.style',
    value: 'Body M',
  },
  {
    forType: 'PhotoPost',
    view: 'PostsListMediaInner',
    fieldId: 'photo',
    key: 'comp.style',
    value: 'wp2',
  },
  {
    forType: 'VideoPost',
    view: 'PostsListMediaInner',
    fieldId: 'video',
    key: 'comp.style',
    value: 'wp2',
  },
  {
    forType: 'Post',
    view: 'SinglePostMediaBottom',
    fieldId: 'text',
    key: 'comp.style',
    value: 'Body M',
  },
  {
    forType: 'PhotoPost',
    view: 'SinglePostMediaInner',
    fieldId: 'photo',
    key: 'comp.style',
    value: 'wp2',
  },
  {
    forType: 'VideoPost',
    view: 'SinglePostMediaInner',
    fieldId: 'video',
    key: 'comp.style',
    value: 'v1',
  },
  {
    forType: 'Post',
    view: 'SinglePostMediaTop',
    fieldId: 'mediaText',
    key: 'comp.style',
    value: 'Body M',
  },
  {
    forType: 'Post',
    view: 'SinglePostMediaBottom',
    fieldId: 'mediaText',
    key: 'comp.style',
    value: 'Body M',
  },
  {
    forType: 'PhotoPost',
    view: 'FeaturedInnerMobile',
    fieldId: 'photo',
    key: 'comp.style',
    value: 'wp1',
  },
  {
    forType: 'VideoPost',
    view: 'FeaturedInnerMobile',
    fieldId: 'video',
    key: 'comp.style',
    value: 'wp1',
  },
  {
    forType: 'Post',
    view: 'MediaLeft',
    fieldId: 'ReadMoreBtn',
    key: 'comp.style',
    value: 'b1',
  },
  {
    forType: 'Post',
    view: 'MediaRight',
    fieldId: 'ReadMoreBtn',
    key: 'comp.style',
    value: 'b1',
  },
  {
    forType: 'Post',
    view: 'Masonry',
    fieldId: 'ReadMoreBtn',
    key: 'comp.style',
    value: 'b1',
  },
  {
    forType: 'Post',
    view: 'MediaZigzag',
    fieldId: 'ReadMoreBtn',
    key: 'comp.style',
    value: 'b1',
  },
  {
    forType: 'Post',
    view: 'MediaTop',
    fieldId: 'ReadMoreBtn',
    key: 'comp.style',
    value: 'b1',
  },
  {
    forType: 'Post',
    view: 'MediaBottom',
    fieldId: 'ReadMoreBtn',
    key: 'comp.style',
    value: 'b1',
  },
  {
    forType: 'Post',
    view: 'MediaLeftPage',
    fieldId: 'ReadMoreBtn',
    key: 'comp.style',
    value: 'b1',
  },
  {
    forType: 'Post',
    view: 'MediaRightPage',
    fieldId: 'ReadMoreBtn',
    key: 'comp.style',
    value: 'b1',
  },
  {
    forType: 'Post',
    view: 'MediaZigzagPage',
    fieldId: 'ReadMoreBtn',
    key: 'comp.style',
    value: 'b1',
  },
  {
    forType: 'Post',
    view: 'MasonryPage',
    fieldId: 'ReadMoreBtn',
    key: 'comp.style',
    value: 'b1',
  },
  {
    forType: 'Post',
    view: 'MediaTopPage',
    fieldId: 'ReadMoreBtn',
    key: 'comp.style',
    value: 'b1',
  },
  {
    forType: 'Post',
    view: 'MediaBottomPage',
    fieldId: 'ReadMoreBtn',
    key: 'comp.style',
    value: 'b1',
  },
  {
    forType: 'PhotoPost',
    view: 'MediaInnerCustom',
    fieldId: 'photo',
    key: 'comp.style',
    value: 'wp1',
  },
  {
    forType: 'VideoPost',
    view: 'MediaInnerCustom',
    fieldId: 'video',
    key: 'comp.style',
    value: 'wp1',
  },
  {
    forType: 'VideoPost',
    view: 'MediaInnerCustom',
    fieldId: 'videoPlayable',
    key: 'comp.style',
    value: 'v1',
  },
  {
    forType: 'PhotoPost',
    view: 'MediaInner',
    fieldId: 'photo',
    key: 'comp.style',
    value: 'wp2',
  },
  {
    forType: 'VideoPost',
    view: 'MediaInner',
    fieldId: 'video',
    key: 'comp.style',
    value: 'wp1',
  },
  {
    forType: 'VideoPost',
    view: 'MediaInner',
    fieldId: 'videoPlayable',
    key: 'comp.style',
    value: 'v1',
  },
  {
    forType: 'Post',
    view: 'MediaLeft',
    fieldId: 'text',
    key: 'comp.style',
    value: 'Body M',
  },
  {
    forType: 'Post',
    view: 'MediaRight',
    fieldId: 'text',
    key: 'comp.style',
    value: 'Body M',
  },
  {
    forType: 'Post',
    view: 'Masonry',
    fieldId: 'text',
    key: 'comp.style',
    value: 'Body M',
  },
  {
    forType: 'Post',
    view: 'MediaTop',
    fieldId: 'text',
    key: 'comp.style',
    value: 'Body M',
  },
  {
    forType: 'Post',
    view: 'MediaBottom',
    fieldId: 'text',
    key: 'comp.style',
    value: 'Body M',
  },
  {
    forType: 'Post',
    view: 'MediaLeftPage',
    fieldId: 'text',
    key: 'comp.style',
    value: 'Body M',
  },
  {
    forType: 'Post',
    view: 'MediaRightPage',
    fieldId: 'text',
    key: 'comp.style',
    value: 'Body M',
  },
  {
    forType: 'Post',
    view: 'MasonryPage',
    key: 'comp.style',
    value: 'Heading S',
    fieldId: 'title',
  },
  {
    forType: 'Post',
    view: 'MasonryPage',
    fieldId: 'text',
    key: 'comp.style',
    value: 'Body M',
  },
  {
    forType: 'Post',
    view: 'MediaTopPage',
    fieldId: 'title',
    key: 'comp.style',
    value: 'Heading M',
  },
  {
    forType: 'Post',
    view: 'MediaTopPage',
    fieldId: 'text',
    key: 'comp.style',
    value: 'Body M',
  },
  {
    forType: 'Post',
    view: 'MediaBottomPage',
    fieldId: 'title',
    key: 'comp.style',
    value: 'Heading M',
  },
  {
    forType: 'Post',
    view: 'MediaBottomPage',
    fieldId: 'text',
    key: 'comp.style',
    value: 'Body M',
  },
  {
    forType: 'Post',
    view: 'Masonry',
    key: 'comp.style',
    value: 'Heading S',
    fieldId: 'title',
  },
  {
    forType: 'Post',
    view: 'Masonry',
    fieldId: 'text',
    key: 'comp.style',
    value: 'Body M',
  },
  {
    forType: 'Post',
    view: 'MediaTop',
    fieldId: 'title',
    key: 'comp.style',
    value: 'Heading M',
  },
  {
    forType: 'Post',
    view: 'MediaTop',
    fieldId: 'text',
    key: 'comp.style',
    value: 'Body M',
  },
  {
    forType: 'Post',
    view: 'MediaBottom',
    fieldId: 'title',
    key: 'comp.style',
    value: 'Heading M',
  },
  {
    forType: 'Post',
    view: 'MediaBottom',
    fieldId: 'text',
    key: 'comp.style',
    value: 'Body M',
  },
  {
    forType: 'Post',
    view: 'MediaZigzagPage',
    fieldId: 'text',
    key: 'comp.style',
    value: 'Body M',
  },
  {
    forType: 'Post',
    view: 'MediaZigzag',
    fieldId: 'text',
    key: 'comp.style',
    value: 'Body M',
  },
  {
    forType: 'Option',
    view: 'ArchiveList',
    fieldId: 'lineSeparator',
    key: 'comp.style',
    value: 'hl1',
  },
  {
    forType: 'Post',
    view: 'MediaLeftPage',
    fieldId: 'def_2',
    key: 'comp.style',
    value: 'c1',
  },
  {
    forType: 'Post',
    view: 'MediaRightPage',
    fieldId: 'def_2',
    key: 'comp.style',
    value: 'c1',
  },
  {
    forType: 'Post',
    view: 'MediaZigzagPage',
    fieldId: 'def_2',
    key: 'comp.style',
    value: 'c1',
  },
  {
    forType: 'Post',
    view: 'MasonryPage',
    fieldId: 'def_1',
    key: 'comp.style',
    value: 'c1',
  },
  {
    forType: 'Post',
    view: 'MasonryPage',
    fieldId: 'def_2',
    key: 'comp.style',
    value: 'c1',
  },
  {
    forType: 'Post',
    view: 'MediaTopPage',
    fieldId: 'def_2',
    key: 'comp.style',
    value: 'c1',
  },
  {
    forType: 'Post',
    view: 'MediaBottomPage',
    fieldId: 'def_2',
    key: 'comp.style',
    value: 'c1',
  },
  {
    forType: 'Post',
    view: 'MediaLeft',
    fieldId: 'def_2',
    key: 'comp.style',
    value: 'c1',
  },
  {
    forType: 'Post',
    view: 'MediaRight',
    fieldId: 'def_2',
    key: 'comp.style',
    value: 'c1',
  },
  {
    forType: 'Post',
    view: 'MediaZigzag',
    fieldId: 'def_2',
    key: 'comp.style',
    value: 'c1',
  },
  {
    forType: 'Post',
    view: 'Masonry',
    fieldId: 'def_1',
    key: 'comp.style',
    value: 'c1',
  },
  {
    forType: 'Post',
    view: 'Masonry',
    fieldId: 'def_2',
    key: 'comp.style',
    value: 'c1',
  },
  {
    forType: 'Post',
    view: 'MediaTop',
    fieldId: 'def_2',
    key: 'comp.style',
    value: 'c1',
  },
  {
    forType: 'Post',
    view: 'MediaBottom',
    fieldId: 'def_2',
    key: 'comp.style',
    value: 'c1',
  },
  {
    forType: 'Post',
    view: 'SinglePostMediaTop',
    fieldId: 'def_1',
    key: 'comp.style',
    value: 'c1',
  },
  {
    forType: 'Option',
    view: 'ArchiveList',
    fieldId: 'date',
    key: 'comp.style',
    value: 'Body M',
  },
  {
    forType: 'Post',
    view: 'SinglePostMediaTop',
    fieldId: 'title',
    key: 'comp.style',
    value: 'Heading M',
  },
  {
    forType: 'Post',
    view: 'SinglePostMediaBottom',
    fieldId: 'title',
    key: 'comp.style',
    value: 'Heading M',
  },
  {
    forType: 'Post',
    view: 'SinglePostMediaTop',
    fieldId: 'tagsValue',
    key: 'comp.style',
    value: 'Body M',
  },
  {
    forType: 'Post',
    view: 'PostsListMediaTop',
    fieldId: 'lineSeparator',
    key: 'comp.style',
    value: 'hl1',
  },
  {
    forType: 'Post',
    view: 'PostsListMediaTop',
    fieldId: 'title',
    key: 'comp.style',
    value: 'Body M',
  },
  {
    forType: 'Post',
    view: 'PostsListMediaBottom',
    fieldId: 'lineSeparator',
    key: 'comp.style',
    value: 'hl1',
  },
  {
    forType: 'Post',
    view: 'PostsListMediaBottom',
    fieldId: 'title',
    key: 'comp.style',
    value: 'Body M',
  },
  {
    forType: 'Post',
    view: 'PostsListMediaLeft',
    fieldId: 'lineSeparator',
    key: 'comp.style',
    value: 'hl1',
  },
  {
    forType: 'Post',
    view: 'PostsListMediaLeft',
    fieldId: 'title',
    key: 'comp.style',
    value: 'Body M',
  },
  {
    forType: 'Option',
    view: 'ArchiveList',
    fieldId: 'itemSeparator',
    key: 'comp.hidden',
    value: 'true',
  },
  {
    forType: 'Post',
    view: 'CategoriesList',
    fieldId: 'category',
    key: 'font.size',
    value: '12px',
  },
  {
    forType: 'Post',
    view: 'SinglePostMediaTop',
    fieldId: 'vars',
    key: 'selectionSharerExperimentOpen',
    value: 'false',
  },
  {
    forType: 'Post',
    view: 'SinglePostMediaBottom',
    fieldId: 'vars',
    key: 'selectionSharerExperimentOpen',
    value: 'false',
  },
  {
    fieldId: 'vars',
    forType: 'Post',
    key: 'newSocialShareButtonsAlignment',
    value: 'left',
    view: 'SinglePostMediaTop',
  },
  {
    fieldId: 'vars',
    forType: 'Post',
    key: 'newFacebookShareButtonIsVisible',
    value: true,
    view: 'SinglePostMediaTop',
  },
  {
    fieldId: 'vars',
    forType: 'Post',
    key: 'newTwitterShareButtonIsVisible',
    value: true,
    view: 'SinglePostMediaTop',
  },
  {
    fieldId: 'vars',
    forType: 'Post',
    key: 'newPinterestShareButtonIsVisible',
    value: true,
    view: 'SinglePostMediaTop',
  },
  {
    fieldId: 'vars',
    forType: 'Post',
    key: 'newGooglePlusShareButtonIsVisible',
    value: true,
    view: 'SinglePostMediaTop',
  },
  {
    fieldId: 'vars',
    forType: 'Post',
    key: 'newWhatsAppShareButtonIsVisible',
    value: true,
    view: 'SinglePostMediaTop',
  },
  {
    fieldId: 'vars',
    forType: 'Post',
    key: 'newLinkedInShareButtonIsVisible',
    value: true,
    view: 'SinglePostMediaTop',
  },
  {
    fieldId: 'vars',
    forType: 'Post',
    key: 'shouldUseNewSocialShareButtons',
    value: false,
    view: 'SinglePostMediaTop',
  },
  {
    fieldId: 'vars',
    forType: 'Post',
    key: 'newSocialShareButtonSize',
    value: 32,
    view: 'SinglePostMediaTop',
  },
  {
    fieldId: 'vars',
    forType: 'Post',
    key: 'newSocialShareButtonsAlignment',
    value: 'left',
    view: 'SinglePostMediaBottom',
  },
  {
    fieldId: 'vars',
    forType: 'Post',
    key: 'newFacebookShareButtonIsVisible',
    value: true,
    view: 'SinglePostMediaBottom',
  },
  {
    fieldId: 'vars',
    forType: 'Post',
    key: 'newTwitterShareButtonIsVisible',
    value: true,
    view: 'SinglePostMediaBottom',
  },
  {
    fieldId: 'vars',
    forType: 'Post',
    key: 'newPinterestShareButtonIsVisible',
    value: true,
    view: 'SinglePostMediaBottom',
  },
  {
    fieldId: 'vars',
    forType: 'Post',
    key: 'newGooglePlusShareButtonIsVisible',
    value: true,
    view: 'SinglePostMediaBottom',
  },
  {
    fieldId: 'vars',
    forType: 'Post',
    key: 'newWhatsAppShareButtonIsVisible',
    value: true,
    view: 'SinglePostMediaBottom',
  },
  {
    fieldId: 'vars',
    forType: 'Post',
    key: 'newLinkedInShareButtonIsVisible',
    value: true,
    view: 'SinglePostMediaBottom',
  },
  {
    fieldId: 'vars',
    forType: 'Post',
    key: 'shouldUseNewSocialShareButtons',
    value: false,
    view: 'SinglePostMediaBottom',
  },
  {
    fieldId: 'vars',
    forType: 'Post',
    key: 'newSocialShareButtonSize',
    value: 32,
    view: 'SinglePostMediaBottom',
  },
  {
    forType: 'Array',
    view: 'Categories',
    fieldId: 'category',
    key: 'comp.style',
    value: 'Body L',
  },
  {
    forType: 'Post',
    view: 'PostsGalleryMediaBottom',
    fieldId: 'title',
    key: 'comp.style',
    value: 'Heading S',
  },
  {
    forType: 'Post',
    view: 'PostsGalleryMediaTop',
    fieldId: 'title',
    key: 'comp.style',
    value: 'Heading S',
  },
];

export const RSS_BUTTON_IMAGES = {
  ORANGE_SQUARE: '062430dbfeba4663a6bf9465b05dee18.png',
  BLACK_CIRCLE: 'b54ca3aedd9b4906a07a75b77e86e102.png',
  GRAY_CIRCLE: '51f52d07264f4f16931efa0a35ac198a.png',
  GRAY_ICON: 'a01185ce2c514940ac4cf52014d681ea.png',
  BLACK_ICON: '175b0e2106e64c33b9e42ae0763a63ab.png',
};

export const RSS_BUTTON_BACKGROUNDS = {
  SQUARE: '1',
  CIRCLE: '2',
  NONE: '3',
};

export const RSS_BUTTON_COLORS = {
  ORANGE: ['rgba(250,173,77,1)', 1],
  WHITE: ['rgba(255,255,255,1)', 1],
  BLACK: ['rgba(0,0,0,1)', 1],
  GRAY: ['rgba(149,150,150,1)', 1],
};

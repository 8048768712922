import Experiments from '@wix/wix-experiments';
import { WIX_SOCIAL_BLOG_SCOPE } from '../constants/experiments';

let experiments;

const isEnabled = (experiment, value = 'true') => experiments.get(experiment) === value;
const conduct = async () => {
  experiments = new Experiments();
  await experiments.load(WIX_SOCIAL_BLOG_SCOPE);
};

const conductSingle = async (spec, fallbackValue) => {
  experiments = experiments || new Experiments();
  return await experiments.conduct(spec, fallbackValue);
};

export default {
  isEnabled,
  conduct,
  conductSingle,
};
